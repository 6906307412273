import React from "react"

import Layout from "../components/flayout"
import Features from "../components/features";

const FeaturesPage = ({data,location}) => (
  <Layout seoFields={seoFields}>
    <Features data={data} featuresContent={data.allContentJson.edges[0].node.featuresContent} location={location}/>
  </Layout>
)

const seoFields = {
  description: "Learn more about the features and functionality that make MProfit an essential solution for Indian Investors, Advisors and Chartered Accountants to manage and track investment portfolios on web and mobile devices.",
  title: "Features",
  keywords: [
    'capital gains',
    'annualised returns',
    'xirr',
    'mutual fund CAS statement',
    'CAMS',
    'F&O software',
    'import statements',
    'Grandfathering',
    'long term capital gains',
    'accounting software'
  ]
}

export const query = graphql`
query featureContentQuery{
  featuredReviews: allWordpressWpReview(
    filter: { acf: { featured: {eq: true}}}
  ){
    edges {
      node {
        id
        title
        acf {
          featured
        }
        excerpt
        review_cat
        content
        date
      }
    }
  }
  reviewCategs: allWordpressWpReviewCat {
    edges {
        node {
            wordpress_id
            slug
        }
    }
  }
  allContentJson {
    edges {
      node {
        featuresContent {
          banner {
            headline
            text
          }
          features {
            order
            headline
            graphic
            bullets
          }
        }
      }
    }
  }
}
`

export default FeaturesPage
