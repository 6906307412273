import React from "react"

import { Link } from "gatsby"

import styles from "./index.module.css";
import FeatureList from "../featureList";
import ReviewsSlider from "../reviewsSlider";
import TryForFree from "../tryForFree";
import FeatureTopGraphic from "../../gatsby-images/feature-top-graphic";
import { getHomeFeaturedReviews } from "../../common/gFunctions";
import GoogleReviewsSlider from "../googleReviewsSlider";

class Features extends React.Component {
    constructor(props) {
        super(props);
        const reviewCategs = this.props.data.reviewCategs.edges;
        const financialAdvisors = reviewCategs.find(x => x.node.slug === 'financial-advisors').node.wordpress_id;
        const charteredAccountants = reviewCategs.find(x => x.node.slug === 'chartered-accountants').node.wordpress_id;

        const reviewsList = getHomeFeaturedReviews(this.props.data.featuredReviews.edges,financialAdvisors,charteredAccountants);

        this.state = {
            reviewsList
        }

        this.featureTopRef = React.createRef();

        this.featureRefs = this.props.featuresContent.features.reduce((acc, value) => {
            acc[value.order] = React.createRef();
            return acc;
          }, {});    
    }

    scrollToFeatures = () => {
        setTimeout(()=>{
            // window.scrollTo({ behavior: 'smooth', top: this.featureTopRef.current.offsetTop-20 });
            window.scrollTo({ behavior: 'smooth', top: this.featureTopRef.current.offsetTop-20 });
        },5);
    }

    componentDidMount() {
        console.log(this.props.location,'location')
        if (this.props.location && this.props.location.state && this.props.location.state.activeFeature) {
            const activeRef = this.featureRefs[this.props.location.state.activeFeature];
            console.log(activeRef,'location')
            if (activeRef.current) {
                setTimeout(()=>{
                    activeRef.current.scrollIntoView({ offsetTop: -150});
                    window.scrollBy(0, -150)
                },100);
            }
        }
    }

    render() {
        const { featuresContent } = this.props;

        return (
            <div className={styles.client_main_section}>
                <div className={styles.features_banner_section}>
                    <div className="container">
                        <div className={["row", "align-items-center"].join(" ")}>
                            <div className={["col-md-5 order-md-1 order-2",styles.features_left_section].join(" ")}>
                                <div className={[styles.mpr_main_title].join(' ')}>
                                    <h2 className="mb-4 text-md-left text-center">{featuresContent.banner.headline}</h2>
                                </div>
                                <p className="text-md-left text-center">{featuresContent.banner.text}</p>
                                <div className="d-flex align-items-center justify-content-center justify-content-md-start">
                                    <span className={["btn", "btn-primary", "rounded"].join(" ")} onClick={this.scrollToFeatures}>View Features</span>
                                    {/* <Link className={["btn", "btn-primary", "rounded"].join(" ")} to="/pricing">Compare Products</Link> */}
                                </div>
                            </div>
                            <div className="col-md-7 order-md-2 order-1 mb-5 mb-md-0">
                                <FeatureTopGraphic/>
                            </div>        
                        </div>
                    </div>
                </div>

                <div className={styles.features_section} ref={this.featureTopRef}>
                    {
                        featuresContent.features.map((feature,index) => <FeatureList key={index} feature={feature} imageOnRight={index%2===1} ref={this.featureRefs[feature.order]}/> )
                    }
                        
                </div>
                
                {/* <ReviewsSlider reviewsList={this.state.reviewsList}/> */}
                <GoogleReviewsSlider />
    
                <TryForFree/>
            </div>
        )
    }
}

export default Features
